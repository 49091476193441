.LoginButton-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.LoginButton-Profile-body {
  display: flex;
  align-items: center;
  gap: 16px;
}

.LoginButton-Loader-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginButton-Profile-img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.LoginButton-Profile-name {
  flex: 1;
}

.LoginButton-Profile-logout {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fae100;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
