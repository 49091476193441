$colors: (
  "g0": #ffffff,
  "g1": #ffffff,
  "g2": #ffffff,
  "g3": #ffffff,
  "g4": #ffffff,
  "g5": #777777,
  "g6": #ffffff,
  "g7": #ffffff,
  "g8": #e2e2e2,
  "g9": #f6f6f6,
  "pt": #222222,
  "st": #ffffff,
  "et": #ffffff,
  "p": #f6f6f6,
  "pl": #ffffff,
  "pd": #ffffff,
  "s": #ffffff,
  "sl": #ffffff,
  "sd": #ffffff,
  "b": #f44336,
  "bl": #ffffff,
  "bd": #ffffff,
  "g": #2bac76,
  "gl": #ffffff,
  "gd": #ffffff,
);

$font-sizes: (
  "12": (
    12px,
    16px,
  ),
  "14": (
    14px,
    20px,
  ),
  "16": (
    16px,
    24px,
  ),
  "18": (
    18px,
    26px,
  ),
  "20": (
    20px,
    24px,
  ),
  "22": (
    22px,
    26px,
  ),
  "24": (
    24px,
    28px,
  ),
  "26": (
    26px,
    30px,
  ),
  "32": (
    32px,
    40px,
  ),
  "40": (
    40px,
    46px,
  ),
  "48": (
    48px,
    52px,
  ),
  "60": (
    60px,
    66px,
  ),
);

$font-weights: (
  "l": (
    400,
    "Circular Std",
  ),
  "r": (
    500,
    "Circular Std",
  ),
  "m": (
    700,
    "Circular Std",
  ),
  "em": (
    800,
    "Circular Std",
  ),
  "b": (
    900,
    "Circular Std",
  ),
);

@each $key, $value in $colors {
  .c-#{$key} {
    color: $value;
  }
}

@each $key, $value in $font-weights {
  .t-#{$key} {
    font-weight: nth($value, 1);
    font-family: nth($value, 2);
  }
}

@each $key, $values in $font-sizes {
  .t-#{$key} {
    font-size: nth($values, 1);
    line-height: nth($values, 2);
    min-height: nth($values, 2);
    font-weight: 500;
  }

  @each $key2, $value2 in $font-weights {
    .t-#{$key}-#{$key2} {
      font-size: nth($values, 1);
      line-height: nth($values, 2);
      min-height: nth($values, 2);
      font-weight: nth($value2, 1);
      font-family: nth($value2, 2);
    }
  }
}

.f-h {
  display: flex;
  flex-direction: row;
}

.f-v {
  display: flex;
  flex-direction: column;
}

.hover-popup {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 3000;
}

// Miscellanous
.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.sized {
  flex-shrink: 0;
}

.clickable {
  pointer-events: all;
}

.unclickable {
  pointer-events: none;
}

.ovf-h {
  overflow: hidden;
}

.ovf-s {
  overflow: scroll !important;
  overscroll-behavior-x: contain;
}

.ovf-a {
  overflow: auto !important;
  overscroll-behavior-x: contain;
}

.ovf-s-d {
  overflow: scroll !important;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.ovf-s-d::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hover:hover {
  background-color: #e4e4e4;
}

.hover:hover .hover-popup {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.transition {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
