@import "/src/layouts/layout_generated.scss";

/*
@font-face {
  font-family: "Circular Std";
  src: url("fonts/CircularStd-Bold.woff") format("woff"),
    url("fonts/CircularStd-Bold.woff2") format("woff2");
  font-weight: 900;
}

@font-face {
  font-family: "Circular Std";
  src: url("fonts/CircularStd-Medium.woff") format("woff"),
    url("fonts/CircularStd-Medium.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Circular Std";
  src: url("fonts/CircularStd-Book.woff") format("woff"),
    url("fonts/CircularStd-Book.woff2") format("woff2");
  font-weight: 500;
}
*/
button,
textarea {
  font-family: "Apple SD Gothic Neo";
  font-weight: 500;
  font-style: normal;
  resize: none;
}

iframe {
  border: none;
  height: auto;
}

div {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Apple SD Gothic Neo";
  font-weight: 500;
  font-style: normal;
  margin: 0px;
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#root {
  height: 100%;
}

.Root-Editor {
  width: 30vw;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.Root-Editor-Input {
  font-size: 20px;
  height: 100%;
}

@media screen and (max-width: 420px) {
  .Root-Editor {
    display: none;
  }
}

.Loader-large {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.Loader-small {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input {
  border: none;
  background-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @extend .c-g1;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @extend .c-g1;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  @extend .c-g1;
}

.divider {
  @extend .bc-g8;
  height: 1px;
}
