@import "src/layouts/layout_generated.scss";

.button-base {
  @extend .c-c;
  @extend .p-12-rl;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.confirm-button {
  @extend .button-base;
  @extend .t-16-pp;
  @extend .bc-pt;
  @extend .bdr-8;

  height: 40px;
  width: 80px;

  &.disabled {
    @extend .t-16-g6;
    @extend .bc-g3;
    @extend .bd-g5;
  }
}

.done-button {
  @extend .button-base;
  @extend .t-16-hd;
  @extend .bc-hl;
  @extend .bdr-8;

  height: 40px;
  width: 80px;

  &.disabled {
    @extend .bc-pt;
    opacity: 0.2;
  }
}

.cancel-button {
  @extend .button-base;
  @extend .t-16-pt;
  @extend .bdr-8;

  height: 40px;
  width: 80px;
  background: transparent;

  &.disabled {
    @extend .bc-pt;
    opacity: 0.2;
  }
}

.secondary-button {
  @extend .button-base;
  @extend .bc-wt;
  @extend .t-16-pt;
  @extend .bdr-8;
  @extend .bdw-1-pt;

  height: 40px;

  &.disabled {
    @extend .t-16-m-g5;
    @extend .bdw-1-g7;
    opacity: 0.5;
  }
}
.primary-button {
  @extend .button-base;
  @extend .t-16-pp;
  @extend .bc-pt;
  @extend .bdr-8;

  height: 40px;
  width: fit-content;

  &.disabled {
    @extend .t-16-g6;
    @extend .bc-g3;
    @extend .bd-g5;
  }
}
