.RadioButton-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px;
}

.RadioButton-body-h {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 6px;
}

.RadioButton-body-v {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

.RadioButton-option {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.RadioButton-option-active {
  color: #1890ff;
  background-color: white;
  -webkit-box-shadow: inset 0px 0px 0px 2px #1890ff;
  -moz-box-shadow: inset 0px 0px 0px 2px #1890ff;
  box-shadow: inset 0px 0px 0px 2px #1890ff;
}

.RadioButton-option-inactive {
  color: #79747e;
  -webkit-box-shadow: inset 0px 0px 0px 1px #79747e;
  -moz-box-shadow: inset 0px 0px 0px 1px #79747e;
  box-shadow: inset 0px 0px 0px 1px #79747e;
}

.RadioButton-body-option-h {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 40px;
}

.RadioButton-body-option-v {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  height: 48px;
}

.RadioButton-option-left {
  justify-content: first baseline;
}

.RadioButton-option-center {
  justify-content: center;
}

.RadioButton-radio-inactive {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 14px;
  margin-right: 10px;
  border: 2px solid #8f918e;
}

.RadioButton-radio-active {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 14px;
  margin-right: 10px;
  border: 2px solid #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RadioButton-radio-inner-active {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #1890ff;
}
