
$flex: ("-1": 1, "-2": 2, "-3": 3);

@each $key, $value in $flex {
    .f#{$key} {
        flex: $value;
    }
}

.c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

$flexAlignItems: ("c": center, "s": flex-start, "e": flex-end, "st": stretch, "b": baseline);

@each $key, $value in $flexAlignItems {
    .a-#{$key} {
        align-items: $value;
    }

    .as-#{$key} {
      align-self: $value;
  }
}
  
$flexJustifyContent: ("c": center, "s": flex-start, "e": space-evenly, "b": space-between, "a": space-around);

@each $key, $value in $flexJustifyContent {
    .j-#{$key} {
        justify-content: $value;
    }
}
  
$gap: ("2": 2px, "4": 4px, "6": 6px, "8": 8px, "10": 10px, "12": 12px, "16": 16px, "20": 20px, "24": 24px, "32": 32px);

@each $key, $value in $gap {
    .g-#{$key} {
        gap: $value;
    }
}
  
$color: ("g0": #131313, "g1": #FFFFFF, "g2": #F1F1F1, "g3": #F5F5F5, "g4": #C8C8C8, "g5": #D9D9D9, "g6": #BFBFBF, "g7": #8C8C8C, "g8": #e2e2e2, "g9": #ffffff, "pt": #0288d1, "pt2": #116fa2, "pt3": #1A5483, "st": #ffffff, "et": #ffffff, "pp": #f6f6f6, "pl": #e4e4e4, "pd": #ffffff, "ss": #ffffff, "sl": #ffffff, "sd": #ffffff, "bb": #FF7676, "bl": #ffffff, "bd": #ffffff, "gg": #2bac76, "gl": #ffffff, "gd": #ffffff, "hd": #03A9F4, "hh": #004175, "hl": #E1F5FE, "wt": #ffffff);

  
$opacity: ("0": 0, "1": 19, "2": 33, "3": 4c, "4": 66, "5": 80, "6": 99, "7": b3, "8": cc, "9": e6, "10": 100);
  
@each $key, $value in $color {
    .c-#{$key} {
        color: $value;
    }

    .bc-#{$key} {
        background-color: $value;
    }

    @each $key2, $value2 in $opacity {
      .bc-#{$key}-#{$key2} {
          background-color: #{$value}#{$value2};
      }
    }
}
  
$padding: ("2": 2px, "4": 4px, "8": 8px, "12": 12px, "16": 16px, "20": 20px, "24": 24px, "28": 28px, "32": 32px);

$dir: ("": ("-top", "-bottom", "-right", "-left"), "-t": ("-top"), "-b": ("-bottom"), "-r": ("-right"), "-l": ("-left"), "-tb": ("-top", "-bottom"), "-tr": ("-top", "-right"), "-tl": ("-top", "-left"), "-br": ("-bottom", "-right"), "-bl": ("-bottom", "-left"), "-rl": ("-right", "-left"), "-tbr": ("-top", "-bottom", "-right"), "-tbl": ("-top", "-bottom", "-left"), "-trl": ("-top", "-right", "-left"), "-brl": ("-bottom", "-right", "-left"));

$width: ("-1": 1, "-2": 2, "-3": 3);

@each $key, $value in $width {
    .sdw#{$key} {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }

    .dsdw#{$key} {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
    }

    .l#{$key} {
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: #{$value};
        -webkit-box-orient: vertical;
    }
}

@each $d, $suffixes in $dir {
    .bd#{$d} {
        @each $suffix in $suffixes {
            border#{$suffix}: 1px solid #e4e4e4;
        }
    }
    @each $key2, $value2 in $color {
      .bd#{$d}-#{$key2} {
        @each $suffix in $suffixes {
          border#{$suffix}: 1px solid #{$value2};
        }
      }
    }
    @each $key, $value in $width {
        .bdw#{$key}#{$d} {
            @each $suffix in $suffixes {
                border#{$suffix}: #{$value}px solid #e4e4e4;
            }
        }
        @each $key2, $value2 in $color {
          .bdw#{$key}#{$d}-#{$key2} {
            @each $suffix in $suffixes {
              border#{$suffix}: #{$value}px solid #{$value2};
            }
          }
        }
    }
    @each $key, $value in $padding {
        .m-#{$key}#{$d} {
        @each $suffix in $suffixes {
            margin#{$suffix}: #{$value};
        }
        }
        .p-#{$key}#{$d} {
        @each $suffix in $suffixes {
            padding#{$suffix}: #{$value};
        }
        }
    }
}

$font-size: ("10": (10px, 14px), "12": (12px, 16px), "14": (14px, 20px), "16": (16px, 24px), "18": (18px, 26px), "20": (20px, 24px), "22": (22px, 26px), "24": (24px, 28px), "28": (28px, 30px), "32": (32px, 40px), "36": (36px, 44px), "40": (40px, 46px), "48": (48px, 52px), "56": (56px, 66px), "64": (64px, 70px));

$font-weight: ("b": (900, "Apple SD Gothic Neo"), "m": (700, "Apple SD Gothic Neo"), "r": (500, "Apple SD Gothic Neo"));

@each $key, $value in $font-weight {
  .t-#{$key} {
    font-weight: nth($value, 1);
    font-family: nth($value, 2);
  }
}
@each $key, $values in $font-size {
  .t-#{$key} {
    font-size: nth($values, 1);
    line-height: nth($values, 2);
    min-height: nth($values, 2);
    font-weight: 500;
  }
  @each $key2, $value2 in $color {
    .t-#{$key}-#{$key2} {
      font-size: nth($values, 1);
      line-height: nth($values, 2);
      min-height: nth($values, 2);
      color: $value2;
      font-weight: 500;
    }
  }
  @each $key2, $value2 in $font-weight {
    .t-#{$key}-#{$key2} {
      font-size: nth($values, 1);
      line-height: nth($values, 2);
      min-height: nth($values, 2);
      font-weight: nth($value2, 1);
      font-family: nth($value2, 2);
    }
    @each $key3, $value3 in $color {
      .t-#{$key}-#{$key2}-#{$key3} {
        font-size: nth($values, 1);
        line-height: nth($values, 2);
        min-height: nth($values, 2);
        font-weight: nth($value2, 1);
        font-family: nth($value2, 2);
        color: $value3;
      }
    }
  }
}
      
$dimensions: ("2": 2px, "4": 4px, "6": 6px, "8": 8px, "12": 12px, "16": 16px, "20": 20px, "24": 24px, "28": 28px, "32": 32px, "40": 40px, "85": 85px, "100": 100px);

@each $key, $value in $dimensions {
    .d-#{$key} {
        width: $value;
        height: $value;
    }
    .dh-#{$key} {
        height: $value;
    }
    .dw-#{$key} {
        width: $value;
    }

    @each $key2, $value2 in $dimensions {
        .d-#{$key}-#{$key2} {
            width: $value;
            height: $value2;
        }
    }

    .bdr-#{$key} {
        overflow: hidden;
        border-radius: $value;
    }
}

